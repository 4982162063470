import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'urlFriendly'
})

export class UrlFriendlyPipe implements PipeTransform {
  transform(value: string): string {
    if (!value) {
      return '';
    }

    // Normalize the string to remove accents and special characters
    const normalized = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');

    // Convert to lower case and replace spaces with dashes
    const urlFriendly = normalized
      .toLowerCase()                     // Convert to lower case
      .replace(/[\s]+/g, '-')            // Replace spaces with dashes
      .replace(/[^\w\-]+/g, '')          // Remove all non-word chars
      .replace(/\-\-+/g, '-')            // Replace multiple dashes with a single dash
      .replace(/^-+|-+$/g, '');          // Trim dashes from start and end

    return urlFriendly;
  }
}