import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'nonBreakingSpace'
})
export class NonBreakingSpacePipe implements PipeTransform {

  // List of Czech prepositions and conjunctions that should not be separated from the next word
  private prepositions = ['k', 's', 'z', 'v', 'o', 'u', 'a', 'i', 'do', 'na', 'po', 'ke', 'se', 'ze', 've'];

  transform(value: string): string {
    if (!value) {
      return '';
    }

    // Create a regular expression to match these prepositions followed by a space
    const regex = new RegExp(`\\b(${this.prepositions.join('|')})\\s`, 'gi');

    // Replace the space with a non-breaking space (Unicode: \u00A0)
    return value.replace(regex, '$1\u00A0');
  }
}