import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { Media } from 'src/app/services/api-connection.service';

@Component({
  selector: 'app-image-gallery',
  templateUrl: './image-gallery.component.html',
  styleUrls: ['./image-gallery.component.scss']
})

export class ImageGalleryComponent implements OnChanges {
  @Input() media: Media[] = [];

  filtered: Media[] = [];
  selected!: Media;
  
  ngOnChanges(changes: SimpleChanges): void {
    if(changes['media']) {
      this.filtered = this.media.filter(x => x.type == "image");
      this.selected = this.filtered[0];
    }
  }
}
