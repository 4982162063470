<app-navbar></app-navbar>
<section class="container">
    <div class="grid">
        <ng-container *ngFor="let c of categories">
            <section *ngIf="filtered[c.id].length > 0" class="category-table" id="category-{{c.id}}">
                <app-category-header [category]="c"></app-category-header>
                <app-aid-cards-grid [aid_cards]="filtered[c.id]"></app-aid-cards-grid>
            </section>
        </ng-container>
    </div>
    <aside class="filter">
        <div #boundary class="sticky-boundary">
            <div stickyThing [boundary]="boundary" [marginTop]="100" class="sticky-content">
                <h4>Filtr a řazení</h4>
                <ul class="filter-category">
                    <ng-container *ngFor="let c of categories">
                        <li *ngIf="filtered[c.id].length > 0">
                            <a (click)="goTo(c.id)" [class.active]="category_id == c.id">{{c.name}} / <span style="color: #{{c.color}};">{{c.abbr}}</span></a>
                        </li>
                    </ng-container>
                </ul>
                <ul class="filter-category show-sticky">
                    <li>
                        <a (click)="goTo()">Zpět nahoru</a>
                    </li>
                </ul>
            </div>
        </div>
    </aside>
</section>