import { Component, Input } from '@angular/core';
import { AidCard } from 'src/app/services/api-connection.service';

@Component({
  selector: 'app-aid-cards-grid',
  templateUrl: './aid-cards-grid.component.html',
  styleUrls: ['./aid-cards-grid.component.scss']
})

export class AidCardsGridComponent {
  @Input() aid_cards: AidCard[] = [];
}
