import { Injectable } from '@angular/core';
import { Observable, Subscriber } from 'rxjs';
import { AidCard } from './api-connection.service';

@Injectable({
  providedIn: 'root'
})
export class CartService {
  private is_empty_subscribers: Subscriber<boolean>[] = [];
  private n_items_subscribers: Subscriber<number>[] = [];
  private items: {aid: {id: number, title: string}, quantity: number}[] = [];

  constructor() { }

  isEmpty() {
    return new Observable<boolean>(subscriber => {
      this.is_empty_subscribers.push(subscriber);
      this.notify();
    });
  }

  nItems() {
    return new Observable<number>(subscriber => {
      this.n_items_subscribers.push(subscriber);
      this.notify();
    });
  }

  notify() {
    let n_items = 0;
    this.items.forEach(i => n_items += i.quantity);

    this.is_empty_subscribers.forEach(s => s.next(n_items < 1));
    this.n_items_subscribers.forEach(s => s.next(n_items));
  }

  add(aid: {id: number, title: string}, quantity: number) {
    const tmp = this.items.find(i => i.aid == aid);
    if(tmp) tmp.quantity += quantity;
    else this.items.push({aid, quantity});
    this.notify();
  }
}
