import { Component, Input } from '@angular/core';
import { CartService } from '../../services/cart.service'

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {
  @Input() search: false | ((query: string) => any) = (query: string) => console.log("Search: " + query);
  @Input() entries: {text: string, path: string}[] = [{text: "Pomůcky", path: "/pomucky"}, {text: "O nás", path: "/o-nas"}, {text: "Kontakt", path: "/kontakt"}];

  plural: {[k: string]: string} = {
    '=1': 'položka',
    '=2': 'položky',
    '=3': 'položky',
    '=4': 'položky',
    'other': 'položek'
  };

  constructor(public cart: CartService) {}
}
