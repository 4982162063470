import { Component, Input } from '@angular/core';
import { Category } from 'src/app/services/api-connection.service';

@Component({
  selector: 'app-category-header',
  templateUrl: './category-header.component.html',
  styleUrls: ['./category-header.component.scss']
})
export class CategoryHeaderComponent {
  @Input() category!: Category;
}
