<nav>
    <a routerLink="/" app-logo color="#86A483"></a>
    <div class="divider"></div>
    <div class="description quicksand">Rozvojové pomůcky <br>nejen pro komunikaci</div>
    <div class="search" *ngIf="search"><img src="/assets/icon/magnifying-glass.svg" alt=""><input type="search" placeholder="Co hledáte?"></div>
    <span class="spacer"></span>
    <ul class="entries quicksand">
        <li *ngFor="let e of entries"><a [routerLink]="e.path" routerLinkActive="active" title="{{e.text}}">{{e.text}}</a></li>
    </ul>
    <a routerLink="cart" class="cart" title="Košík - {{cart.nItems() | async}} {{cart.nItems() | async | i18nPlural: plural}}"><img [src]="(cart.isEmpty() | async) ? '/assets/icon/cart-empty.svg' : '/assets/icon/cart-full.svg'" alt="Košík"></a>
</nav>