import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-quantity-input',
  templateUrl: './quantity-input.component.html',
  styleUrls: ['./quantity-input.component.scss']
})
export class QuantityInputComponent implements OnChanges {
  @Input() quantity = 1;
  @Output() quantityChange: EventEmitter<number> = new EventEmitter<number>();

  ngOnChanges(changes: SimpleChanges): void {
    if(changes["quantity"]) {
      if(this.quantity < 1) this.quantity = 1;
      this.quantityChange.emit(this.quantity);
    }
  }
}
