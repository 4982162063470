import { Component, Input } from '@angular/core';
import { Category } from 'src/app/services/api-connection.service';

@Component({
  selector: 'app-catgories-chips',
  templateUrl: './categories-chips.component.html',
  styleUrls: ['./categories-chips.component.scss']
})

export class CategoriesChipsComponent {
  @Input() categories: Category[] = [];
}
