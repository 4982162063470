import { Component, Input } from '@angular/core';
import { Aid } from 'src/app/services/api-connection.service';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-aid-detail',
  templateUrl: './aid-detail.component.html',
  styleUrls: ['./aid-detail.component.scss']
})

export class AidDetailComponent {
  @Input() aid!: Aid;

  quantity = 1;

  constructor(public cart: CartService) {}
}
