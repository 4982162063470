<section class="container">
    <app-image-gallery [media]="aid.media"></app-image-gallery>
    <section class="short-description">
        <h1>{{aid.title}}</h1>
        <p class="description">{{aid.short_desc | nonBreakingSpace}}</p>
        <app-catgories-chips [categories]="aid.categories"></app-catgories-chips>
        <section class="row">
            <p class="price quicksand">{{aid.price | czechCurrency}}</p>
            <app-quantity-input [(quantity)]="quantity"></app-quantity-input>
            <button class="green" title="Přidat do košíku" (click)="cart.add(aid, quantity)">Přidat do košíku</button>
        </section>
    </section>
    <section class="long-description" [innerHTML]="aid.long_desc | nonBreakingSpace">
    </section>
</section>