import { Component } from '@angular/core';
import { AidCard, ApiConnectionService } from 'src/app/services/api-connection.service';

@Component({
  selector: 'app-newest-aids',
  templateUrl: './newest-aids.component.html',
  styleUrls: ['./newest-aids.component.scss']
})

export class NewestAidsComponent {
  constructor(public api: ApiConnectionService) {}

  aid_cards: AidCard[] = [];

  async ngOnInit() {
    this.aid_cards = await this.api.getNewestAids();
  }
}
