<section class="card">
    <a [routerLink]="['/pomucka', (aid_card.title| urlFriendly), aid_card.id]" title="{{aid_card.title}}">
        <img alt="Náhled" src="/media/{{aid_card.image}}">
        <h4>{{aid_card.title}}</h4>
        <p>{{aid_card.short_desc | nonBreakingSpace | truncate:100:true}}</p>
    </a>
    <div class="footer">
        <app-catgories-chips [categories]="aid_card.categories"></app-catgories-chips>
        <button class="green" title="Přidat do košíku" (click)="cart.add(aid_card, 1)">Koupit</button>
    </div>
</section>