<section class="container">
    <section class="infobox">
        <h2>Vyberte si z naší nabídky pomůcek</h2>
        <p>Na našem webu nabízíme široký výběr rozvojových logopedických pomůcek, které jsou rozděleny do dvanácti kategorií, aby vyhovovaly specifickým potřebám každého dítěte. Každá kategorie je pečlivě sestavena tak, aby poskytovala efektivní a cílenou podporu při rozvoji řečových a komunikačních schopností. Prozkoumejte naši nabídku a najděte ty nejvhodnější pomůcky pro vaše potřeby!</p>
    </section>
    <ul>
        <li *ngFor="let c of category">
            <a [routerLink]="['/pomucky', (c.name | urlFriendly), c.id]" title="{{c.name}}" [style]="'background: #' + c.color + ';'">{{c.name}}</a>
        </li>
    </ul>
</section>