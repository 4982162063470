import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { PageComponent } from './page/page.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { HomeComponent } from './public/home/home.component';
import { LogoComponent } from './components/logo.component';
import { AidCategoriesComponent } from './components/aid-categories/aid-categories.component';
import { AidCardComponent } from './components/aid-card/aid-card.component';
import { NewestAidsComponent } from './components/newest-aids/newest-aids.component';
import { TruncatePipe } from './pipes/truncate.pipe';
import { UrlFriendlyPipe } from './pipes/url-friendly.pipe';
import { AidComponent } from './public/aid/aid.component';
import { AidDetailComponent } from './components/aid-detail/aid-detail.component';
import { ImageGalleryComponent } from './components/image-gallery/image-gallery.component';
import { CategoriesChipsComponent } from './components/categories-chips/categories-chips.component';
import { CzechCurrencyPipe } from './pipes/czech-currency.pipe';
import { QuantityInputComponent } from './components/quantity-input/quantity-input.component';
import { FormsModule } from '@angular/forms';
import { NonBreakingSpacePipe } from './pipes/non-breaking-space.pipe';
import { AidCardsGridComponent } from './components/aid-cards-grid/aid-cards-grid.component';
import { AidListComponent } from './public/aid-list/aid-list.component';
import { CategoryHeaderComponent } from './components/category-header/category-header.component';
import { BreadcrumbComponent } from './components/breadcrumb/breadcrumb.component';
import { AngularStickyThingsModule } from '@w11k/angular-sticky-things';

@NgModule({
  declarations: [
    PageComponent,
    NavbarComponent,
    HomeComponent,
    AidComponent,
    LogoComponent,
    AidCategoriesComponent,
    AidCardComponent,
    AidDetailComponent,
    ImageGalleryComponent,
    CategoriesChipsComponent,
    NewestAidsComponent,
    TruncatePipe,
    UrlFriendlyPipe,
    CzechCurrencyPipe,
    QuantityInputComponent,
    NonBreakingSpacePipe,
    AidCardsGridComponent,
    AidListComponent,
    CategoryHeaderComponent,
    BreadcrumbComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    AngularStickyThingsModule
  ],
  providers: [],
  bootstrap: [PageComponent]
})
export class AppModule { }
