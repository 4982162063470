import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { UrlFriendlyPipe } from 'src/app/pipes/url-friendly.pipe';
import { Aid, ApiConnectionService } from 'src/app/services/api-connection.service';

@Component({
  selector: 'app-aid',
  templateUrl: './aid.component.html',
  styleUrls: ['./aid.component.scss'],
  providers: [UrlFriendlyPipe]
})
export class AidComponent implements OnInit {
  aid!: Aid;
  bc_path: {text: string, link: string[]}[][] = [];

  constructor(private route: ActivatedRoute, private title: Title, private urlFriendly: UrlFriendlyPipe, public api: ApiConnectionService) {}

  ngOnInit(): void {
    this.route.params.subscribe(async params => {
      this.aid = await this.api.getAid(params['id']);
      this.title.setTitle(this.aid.title + " - Hopskok");
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.bc_path = [
        [{text: "Rozvojové pomůcky", link: ["/pomucky"]}],
        this.aid.categories.map<{text: string; link: string[]}>(c => {return {text: c.name, link: ["/pomucky", this.urlFriendly.transform(c.name), c.id.toString()]};}),
        [{text: this.aid.title, link: ["/pomucka", this.urlFriendly.transform(this.aid.title), this.aid.id.toString()]}]
      ];
    });
  }
}
