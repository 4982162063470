import { AfterViewChecked, Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { AidCard, ApiConnectionService, Category } from 'src/app/services/api-connection.service';
import { Location } from '@angular/common'
import { UrlFriendlyPipe } from 'src/app/pipes/url-friendly.pipe';

@Component({
  selector: 'app-aid-list',
  templateUrl: './aid-list.component.html',
  styleUrls: ['./aid-list.component.scss'],
  providers: [UrlFriendlyPipe]
})
export class AidListComponent implements OnInit, AfterViewChecked {
  categories: Category[] = [];
  private aid_cards: AidCard[] = [];
  filtered: {[id: number]: AidCard[]} = {};
  category_id?: number;
  private init_scroll = false;
  
  constructor(private route: ActivatedRoute, private title: Title, private location: Location, private urlFriendly: UrlFriendlyPipe, public api: ApiConnectionService) {}

  ngAfterViewChecked(): void {
    if(this.init_scroll && this.category_id) {
      this.goTo(this.category_id);
      this.init_scroll = false;
    }
  }

  async ngOnInit() {
    this.title.setTitle("Kategorie - Hopskok");
    this.route.params.subscribe(async params => this.goTo(params["id"]));
    this.aid_cards = await this.api.getAllAids();
    this.categories = await this.api.getCategories();
    this.filter();
    this.init_scroll = true;
  }

  goTo(id?: number) {
    this.category_id = id;

    const element = document.getElementById("category-" + this.category_id?.toString());
    if(element) {
      element.scrollIntoView({behavior: 'smooth', block: 'start'});
      const category = this.categories.find(c => c.id == this.category_id);
      this.location.go('/pomucky/' + this.urlFriendly.transform(category?.name as string) + "/" + this.category_id);
      this.title.setTitle(this.categories.find(c => c.id == this.category_id)?.name + " - Hopskok");
    }
    else {
      window.scrollTo({top: 0, behavior: 'smooth'});
      this.location.go('/pomucky');
      this.title.setTitle("Kategorie - Hopskok");
    }
  }

  filter() {
    const tmp = this.aid_cards;
    for(let c of this.categories) this.filtered[c.id] = tmp.filter(a => a.categories.some(k => k.id == c.id));
  }
}
