import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})

export class ApiConnectionService {

  constructor(private http: HttpClient) { }

  private api_url = "/api";

  async getCategories() {
    return new Promise<Category[]>((resolve, reject) => this.http.get<any[]>(this.api_url + "/category/list").subscribe(result => resolve(result)));
  }

  async getAllAids() {
    return new Promise<AidCard[]>((resolve, reject) => this.http.get<any[]>(this.api_url + "/aid/list").subscribe(result => {
        result = result.map(r => {
            r.categories = JSON.parse(r.categories);
            return r;
        });
        resolve(result);
    }));
  }

  async getNewestAids() {
    return new Promise<AidCard[]>((resolve, reject) => this.http.get<any[]>(this.api_url + "/aid/list/newest").subscribe(result => {
        result = result.map(r => {
            r.categories = JSON.parse(r.categories);
            return r;
        });
        resolve(result);
    }));
  }

  async getAid(id: number) {
    return new Promise<Aid>((resolve, reject) => this.http.get<any[]>(this.api_url + "/aid/" + id).subscribe(result => {
        let r = result[0];
        r.categories = JSON.parse(r.categories);
        r.media = JSON.parse(r.media);
        resolve(r);
    }));
  }
}

export type Category = {id: number, name: string, abbr: string, color: string};
export type Media = {id: number, type: "image" | "free-content" | "paid-content", path: string, title: string, desc: string};
export type AidCard = {id: number, title: string, short_desc: string, categories: Category[], image: string};
export type Aid = {id: number, title: string, short_desc: string, long_desc: string, price: number, categories: Category[], media: Media[]};