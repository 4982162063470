import { Component, Input } from '@angular/core';
import { AidCard } from 'src/app/services/api-connection.service';
import { CartService } from 'src/app/services/cart.service';

@Component({
  selector: 'app-aid-card',
  templateUrl: './aid-card.component.html',
  styleUrls: ['./aid-card.component.scss']
})

export class AidCardComponent {
  @Input() aid_card!: AidCard;

  constructor(public cart: CartService) {}
}
