import { Component, OnInit } from '@angular/core';
import { ApiConnectionService } from '../../services/api-connection.service';

@Component({
  selector: 'app-aid-categories',
  templateUrl: './aid-categories.component.html',
  styleUrls: ['./aid-categories.component.scss']
})
export class AidCategoriesComponent implements OnInit {

  constructor(public api: ApiConnectionService) {}

  category: any[] = [];

  async ngOnInit() {
    this.category = await this.api.getCategories();
  }
}