import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'czechCurrency'
})
export class CzechCurrencyPipe implements PipeTransform {
  transform(value: number): string {
    if (value === null || value === undefined) {
      return '';
    }

    // Using the built-in JavaScript Intl.NumberFormat API for Czech currency formatting
    return new Intl.NumberFormat('cs-CZ', {
      style: 'currency',
      currency: 'CZK',
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(value);
  }
}